import React from 'react';
import {useSelector} from 'react-redux';
import './kursinfo.scss';
import {PRIMARY} from "../../primitives/Button";
import {ExternalLink} from "../../primitives/Link";
import {ExtraInfo} from "./ExtraInfo";
import HTMLReactParser from "html-react-parser";
import {ReadMoreText} from "../ReadMoreText";
import {fristHarUtgatt, pages} from "../../utils/common";
import {getKurs, getKursIdObject} from "../../ducks/kursinfo";
import PageTitle from "../PageTitle";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {compose} from "redux";
import MainInfo from "./MainInfo";
import withKursInfoLoaded from "../../high-order-components/withKursInfoLoaded";
import requireKurskatalogAktivert from "../../high-order-components/requireKurskatalogAktivert";
import requireInstitusjonSelected from "../../high-order-components/requireInstitusjonSelected";
import PameldingButton from "../kursoversikt/PameldingButton";
import useLoadSoknader from "../../hooks/useLoadSoknader";
import {getFulltKursVarsel} from "../../utils/kursVarsel";
import {finnesFullfortSoknadForKurs} from "../../ducks/soknader";
import {EksamenTag} from "./EksamenTag";
import Text from "../../primitives/Text";
import Redirect from "../Redirect";

export const Kursinfo = () => {

    useLoadSoknader();

    const kursdata = useSelector(getKurs);
    const kursIdObject = useSelector(getKursIdObject);
    const harFullfortSoknad = useSelector(state => finnesFullfortSoknadForKurs(state, kursIdObject));

    if (!kursdata) {
        return <Redirect to={pages.kurs}/>
    }

    const {
        kursnavn, pris, pameldingsfrist, startdato, varighetTimer, sluttdato,
        eksamen, epost, nettside, emner, kategorier,
        beskrivelse, direktePamelding,
    } = kursdata;

    const {varselTextId, knappTextId, disabledPamelding} = getFulltKursVarsel(kursdata, harFullfortSoknad);

    return (
        <div className={`kursinfo ${beskrivelse && 'with-description'}`}>

            <PageTitle text={kursnavn}/>
            <h1>{kursnavn}</h1>

            <div className='kursinfo__tags'>
                <EksamenTag eksamen={kursdata.eksamen}/>
                {kursdata.nettbasert &&
                <Tag className='nettbasert'>
                    <Text id='nettbasert'/>
                </Tag>
                }
                {kursdata.samlingsbasert &&
                <Tag className='nettbasert'>
                    <Text id={'samlingsbasert'}/>
                </Tag>
                }
                {kategorier && kategorier.map((kategori, index) =>
                    <Tag key={index}>
                        {kategori.navn}
                    </Tag>)
                }
            </div>

            <MainInfo
                pris={pris}
                pameldingsfrist={pameldingsfrist}
                startdato={startdato}
                direktePamelding={direktePamelding}
            />

            {varselTextId &&
            <Feedback type={FEEDBACK_TYPE_VARSEL}>
                <Text id={varselTextId}/>
            </Feedback>
            }

            <AdvarselOmUtgattPameldingsfrist
                direktePamelding={direktePamelding}
                pameldingsfrist={pameldingsfrist}
            />

            <PameldingButton
                id={kursIdObject}
                pameldingsfrist={pameldingsfrist}
                direktePamelding={direktePamelding}
                overrideTextId={knappTextId}
                disablePamelding={disabledPamelding}
                emphasis={PRIMARY}
            />

            <ExtraInfo
                varighetTimer={varighetTimer}
                sluttdato={sluttdato}
                eksamen={eksamen}
                emner={emner}
                epost={epost}
            />

            {nettside &&
            <ExternalLink href={nettside} className='kursets-nettside'>
                <Text id='til_kursets_nettside'/>
            </ExternalLink>
            }

            {beskrivelse &&
            <ReadMoreText className='kursinfo__beskrivelse'
                          textLength={beskrivelse.length}>
                {HTMLReactParser(beskrivelse)}
            </ReadMoreText>
            }

            <PameldingButton
                id={kursIdObject}
                pameldingsfrist={pameldingsfrist}
                direktePamelding={direktePamelding}
                overrideTextId={knappTextId}
                disablePamelding={disabledPamelding}
                emphasis={PRIMARY}
            />

        </div>
    );
};

const Tag = ({className, children}) => (
    <span className={className}>
        {children}
    </span>
);

export const AdvarselOmUtgattPameldingsfrist = ({pameldingsfrist, direktePamelding}) => (
    fristHarUtgatt(pameldingsfrist) &&
    <Feedback>
        {direktePamelding ?
            <Text id='påmeldingsfristen_har_utgått_ikke_mulig_å_melde_på_lenger'/>
            :
            <Text id='søknadsfristen_har_utgått_ikke_mulig_å_registrere_lenger'/>}
    </Feedback>
);

export default compose(
    requireInstitusjonSelected,
    Component => requireKurskatalogAktivert(Component, pages.minSide),
    withKursInfoLoaded,
)(Kursinfo);


